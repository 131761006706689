import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

const BlurRandomTextWrapper = ({ children }) => {
  const wrapperRef = useRef(null);
  const cardRef = useRef(null);

  const applyBlurAndRandomText = (element) => {
    if (element !== cardRef.current) { // Skip blurring the card element
      if (element.nodeType === Node.TEXT_NODE) {
        const originalText = element.textContent;
        const randomizedText = Array.from(originalText)
          .map(char => (char.trim() ? String.fromCharCode(65 + Math.floor(Math.random() * 26)) : char))
          .join('');
        element.textContent = randomizedText;
      } else if (element.nodeType === Node.ELEMENT_NODE) {
        element.style.filter = 'blur(2px)';
        element.childNodes.forEach(applyBlurAndRandomText);
      }
    }
  };

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.childNodes.forEach(applyBlurAndRandomText);
    }
  }, [children]);

  const handleClick = () => {
    // Scroll to the card element smoothly
    if (cardRef.current) {
      cardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  return (
    <div ref={wrapperRef} onClick={handleClick} style={{ cursor: 'pointer', position: 'relative' }}>
      {children}
      <div ref={cardRef} style={{
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        filter: 'none',
        background: 'white', // Adjust background color as needed
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}>
        <h5 style={{ textAlign: 'center' }} className='mb-3'>This information is only available for subscribed users.</h5>
        <Link to="/pricing" className="btn btn-primary">Subscribe Now</Link>
      </div>
    </div>
  );
};

export default BlurRandomTextWrapper;
