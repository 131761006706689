import supported_language from "../supported_languages.json";
import codes from "../codes.json";
import countries from "../countries.json";

function countryFlag(countryName, size) {
  try {
    const reversedCodes = Object.entries(codes).reduce((acc, [code, name]) => {
      acc[name.toLowerCase()] = code;
      return acc;
    }, {});

    return `https://flagcdn.com/${size}/${reversedCodes[countryName.toLowerCase()]}.jpg` || null;
  } catch (error) {
    return "/world.png";
  }
}

function countryName(countryCode) {
  return countries.data.find(country => country.api_code === countryCode)?.country;
}

function countryCode(countryName) {
  const country = countries.data.find(
    (country) => country.country === countryName || country.alternative === countryName
  );
  return country?.api_code;
}

function countryList() {
  return countries.data;
}

function isEU(countryCode) {
  const EU_member_states = ['040', '056', '100', '191', '196', '203', '208', '233', '246', '250', '276', '300', '348', '372', '380', '428', '440', '442', '470', '528', '616', '620', '642', '703', '705', '724', '752'];
  return EU_member_states.includes(countryCode);
}

function supported_languages() {
  return supported_language.text;
}

const ConditionalWrapper = ({ condition, wrapper, children }) => condition ? children : wrapper(children);

export { countryFlag, countryName, countryList, countryCode, isEU, supported_languages, ConditionalWrapper };