import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Link from "Utils/LinkWrapper";
import { countryFlag, countryName, countryCode } from "../../../Utils/Utils";
import ExpandText from "react-expand-text";
import ReactPaginate from "react-paginate";

const ImportRestrictions = ({ candidates, metadata }) => {
  const [currentPage, setCurrentPage] = useState(0); // Adjusting current page to be zero-based
  const [itemsPerPage, setItemsPerPage] = useState(8); // Set items per page
  let [processedCandidates, setProcessedCandidates] = useState([]);

  useEffect(() => {
    if (Array.isArray(candidates)) {
      const newProcessedCandidates = [];
      candidates.forEach((item) => {
        if (item?.antidumping) {
          let country, countryImg, allItems;
          if (item?.metadata?.destination !== "top 50") {
            allItems = [
              ...item.antidumping.antidumping_investigations.map(i => ({ ...i, type: 'Antidumping Investigations' })),
              ...item.antidumping.antidumping_measures.map(i => ({ ...i, type: 'Antidumping Measures' })),
              ...item.antidumping.countervailing_investigations.map(i => ({ ...i, type: 'Countervailing Investigations' })),
              ...item.antidumping.countervailing_measures.map(i => ({ ...i, type: 'Countervailing Measures' })),
            ];

            country = countryName(item?.metadata?.destination);
            countryImg = countryFlag(country, "w80");
          } else {
            allItems = [
              ...item.antidumping.data
            ];
          }

          allItems.forEach((childItem) => {
            const existingItem = newProcessedCandidates.find((item) => item.id === childItem?.code);
            if (!existingItem) {
              if (item?.metadata?.destination === "top 50") {
                country = countryName(countryCode(childItem?.reporting_member));
                countryImg = countryFlag(country, "w80");
              }
              newProcessedCandidates.push({
                id: childItem?.code,
                RegTitle: "Reporting Member: " + childItem?.reporting_member,
                Exporter: "Exporting Country: " + childItem.exporting_member,
                countryImg: countryImg,
                badges: [
                  {
                    id: 1,
                    badgeclassName: "bg-info-subtle text-info",
                    badgeName: "In Force From:  " + childItem.duty_measure_detail?.Measure?.InForceFrom || 'N/A'
                  },
                ],
                info_title: 'Subject Product ',
                info_desc: childItem.subject_product || 'N/A',
                addinfo: childItem.duty_measure_detail?.Measure?.RangeOfIndividualMargins === undefined ? 'Range of Individual Margins: N/A' : "Range of Individual Margins: " + childItem.duty_measure_detail?.Measure?.RangeOfIndividualMargins,
                addinfo2: childItem.duty_measure_detail?.Measure?.Duties?.AllOtherRates ? "All Other Rates: " + childItem.duty_measure_detail.Measure.Duties.AllOtherRates : 'All Other Rates: N/A',
                detailUrl: `/regulation-details?id=${encodeURIComponent(childItem.code)}&searchType=${item.metadata.searchType}&type=${childItem.type}&product=${encodeURIComponent(item.metadata.product)}&destination=${item.metadata.destination === 'top 50' ? countryCode(childItem?.reporting_member) : item.metadata.destination}`,
                type: childItem.type,
                badgeclassName: "bg-primary-subtle text-primary",
              });
            }
          });
        }
        if (item?.importLicensing?.data) {
          let country, countryImg;
          if (item?.metadata?.destination !== "top 50") {
            country = countryName(item?.metadata?.destination);
            countryImg = countryFlag(country, "w80");
          }
          item?.importLicensing?.data?.forEach((childItem) => {
            const existingItem = newProcessedCandidates.find((item) => item.id === childItem?.title);
            if (!existingItem) {
              if (item?.metadata?.destination === "top 50") {
                country = countryName(countryCode(childItem?.member_name));
                countryImg = countryFlag(country, "w80");
              }
              newProcessedCandidates.push({
                id: childItem?.title,
                RegTitle: childItem?.title,
                Exporter: country,
                countryImg: countryImg,
                badges: [
                  {
                    id: 1,
                    badgeclassName: "bg-info-subtle text-info",
                    badgeName: (childItem.legislation_detail?.LatestPublished === '') ? 'Last Published: N/A' : `Last Published:  ${childItem.legislation_detail?.LatestPublished}`
                  },
                ],
                info_title: 'Product Categories ',
                info_desc: Array.isArray(childItem?.product_categories) ? childItem?.product_categories.join(', ') : 'N/A',
                detailUrl: `/regulation-details?id=${encodeURIComponent(childItem.title)}&searchType=${item.metadata.searchType}&type=Import Licensing&product=${encodeURIComponent(item.metadata.product)}&destination=${item.metadata.destination === 'top 50' ? countryCode(childItem?.member_name) : item.metadata.destination}`,
                type: 'Import Licensing',
                badgeclassName: "bg-primary-subtle text-primary",
              });
            }
          });
        }
        if (item?.pr) {
          let country, countryImg;
          if (item?.metadata?.destination !== "top 50") {
            country = countryName(item?.metadata?.destination);
            countryImg = countryFlag(country, "w80");
          }
          item?.pr?.ProductRequirementsDetails?.forEach((childItem) => {
            const existingItem = newProcessedCandidates.find((item) => item.id === childItem?.documentSymbol);
            if (!existingItem) {
              if (item?.metadata?.destination === "top 50") {
                country = countryName(countryCode(childItem?.notifyingMember));
                countryImg = countryFlag(country, "w80");
              }
              newProcessedCandidates.push({
                id: childItem?.documentSymbol,
                RegTitle: childItem?.RequirementTitle || 'N/A',
                Exporter: childItem?.RequirementTitle,
                countryImg: countryImg,
                badges: [
                  {
                    id: 1,
                    badgeclassName: "bg-info-subtle text-info",
                    badgeName: "Distribution Date:  " + (childItem.distributionDate ? new Intl.DateTimeFormat('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(childItem.distributionDate)) : 'N/A')
                  },
                ],
                info_title: 'Product Info ',
                info_desc: childItem?.ProductsInfo || 'N/A',
                detailUrl: `/regulation-details?id=${encodeURIComponent(childItem.documentSymbol)}&searchType=${item.metadata.searchType}&type=Product Requirement&product=${encodeURIComponent(item.metadata.product)}&destination=${item.metadata.destination === 'top 50' ? countryCode(childItem?.notifyingMember) : item.metadata.destination}`,
                badgeclassName: "bg-primary-subtle text-primary",
                type: 'Product Requirement'
              });
            }
          });
        }
        if (item?.qr) {
          let country, countryImg;
          if (item?.metadata?.destination !== "top 50") {
            country = countryName(item?.metadata?.destination);
            countryImg = countryFlag(country, "w80");
          }
          item?.qr?.data?.forEach((childItem) => {
            const existingItem = newProcessedCandidates.find((item) => item.id === childItem?.id);
            if (!existingItem) {
              if (item?.metadata?.destination === "top 50") {
                country = countryName(countryCode(childItem?.reporter_member?.name?.en));
                countryImg = countryFlag(country, "w80");
              }
              newProcessedCandidates.push({
                id: childItem?.id,
                RegTitle: childItem.general_description,
                Exporter: country,
                countryImg: countryImg,
                badges: [
                  {
                    id: 1,
                    badgeclassName: "bg-info-subtle text-info",
                    badgeName: "In Force From:  " + childItem.in_force_from || 'N/A'
                  },
                ],
                info_title: 'Affected Products ',
                info_desc: Array.isArray(childItem?.affected_products) ? childItem?.affected_products.map(item => item?.description?.en).join(', ') : 'N/A',
                detailUrl: `/regulation-details?id=${encodeURIComponent(childItem.id)}&searchType=${item.metadata.searchType}&type=Import Export Restriction&product=${encodeURIComponent(item.metadata.product)}&destination=${item.metadata.destination === 'top 50' ? countryCode(childItem?.reporter_member.name.en) : item.metadata.destination}`,
                type: 'Import Export Restriction',
                badgeclassName: "bg-primary-subtle text-primary",
              });
            }
          });
        }
        if (item?.euTaric) {
          let country, countryImg;
          if (item?.metadata?.destination !== "top 50") {
            country = "European Union";
            countryImg = countryFlag(country, "w80");
          }
          item?.euTaric?.forEach((childItem) => {
            childItem?.measures_details?.forEach((children) => {
              const existingItem = newProcessedCandidates.find((item) => item.id === children?.description + childItem.nomenclature_code);
              if (!existingItem) {
                newProcessedCandidates.push({
                  id: children?.description + childItem.nomenclature_code,
                  RegTitle: children.description,
                  Exporter: "Geographical Area: " + childItem.geographical_area,
                  countryImg: countryImg,
                  badges: [
                    {
                      id: 1,
                      badgeclassName: "bg-info-subtle text-info",
                      badgeName: "Last Update:  " + childItem.last_update || 'N/A'
                    },
                    {
                      id: 2,
                      badgeclassName: "bg-info-subtle text-info",
                      badgeName: "Reference Date:  " + childItem.reference_date || 'N/A'
                    },
                  ],
                  info_title: 'Duty Rate ',
                  info_desc: children?.duty_rate ? children?.duty_rate : 'N/A',
                  detailUrl: `/regulation-details?id=${encodeURIComponent(childItem.nomenclature_code)}&searchType=${item.metadata.searchType}&type=EU Commission&product=${encodeURIComponent(item.metadata.product)}${childItem?.mtech_partner_code ? `&origin=${childItem.mtech_partner_code}` : ''}&destination=${item.metadata.destination === 'top 50' ? countryCode(childItem?.reporter_member.name.en) : item.metadata.destination}`,
                  type: 'EU Commission',
                  badgeclassName: "bg-dark-subtle text-dark",
                });
              }
            })
          });
        }
        setProcessedCandidates(newProcessedCandidates);
      });
    }
  }, [candidates]);

  let pageCount;

  const indexOfLastCandidate = (currentPage + 1) * itemsPerPage; // Last index of current page
  const indexOfFirstCandidate = indexOfLastCandidate - itemsPerPage; // First index of current page
  const currentCandidates = metadata?.page === "all"
    ? processedCandidates // Show all candidates
    : processedCandidates.slice(indexOfFirstCandidate, indexOfLastCandidate); // Paginate

  if (metadata?.page === "all") {
    pageCount = 1;
  } else {
    pageCount = Math.ceil(processedCandidates.length / itemsPerPage);
  }

  const handlePageClick = (data) => {
    setCurrentPage(data.selected); // Update current page
  };

  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col lg={8}>
          <div className="mb-3 mt-3 mb-lg-0">
            <h6 className="fs-16 mb-0">Showing {processedCandidates.length} results</h6>
          </div>
        </Col>

        <Col lg={4}>
          <div className="candidate-list-widgets">
            <Row>
              <Col lg={12}>
                <div className="selection-widget mt-2 mt-lg-0">
                  <select
                    className="form-select"
                    data-trigger
                    onChange={(e) => {
                      setItemsPerPage(Number(e.target.value));
                      setCurrentPage(0);
                    }}
                    aria-label="Default select example"
                  >
                    <option value={8}>8 per Page</option>
                    <option value={12}>12 per Page</option>
                    <option value={processedCandidates.length}>All</option>
                  </select>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {(currentCandidates || []).map((featuredJobdetails, key) => (
        <div
          key={key}
          className={
            featuredJobdetails.addclassNameBookmark === true
              ? "job-box bookmark-post card mt-4"
              : "job-box card mt-4"
          }
        >
          <div className="p-4">
            <Row className="align-items-center">
              <Col md={2}>
                <div className="text-center mb-4 mb-md-0">
                  <Link to={featuredJobdetails.detailUrl}>
                    <img
                      src={featuredJobdetails.countryImg}
                      alt=""
                      className="img-fluid rounded-3"
                    />
                  </Link>
                </div>
              </Col>

              <Col >
                <div className="mb-2 mb-md-0">
                  <h5 className="fs-18 mb-1">
                    <Link to={featuredJobdetails.detailUrl} className="text-dark">
                      {featuredJobdetails.RegTitle ? (
                        <ExpandText text={featuredJobdetails.RegTitle} maxLength={100} />
                      ) : (
                        "N/A"
                      )}
                    </Link>
                  </h5>
                  <p className="text-muted fs-14 mb-0">
                    {featuredJobdetails.Exporter ? (
                      <ExpandText
                        text={featuredJobdetails.Exporter}
                        maxLength={100}
                      />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
              </Col>
              {
                featuredJobdetails.addinfo && (
                  <Col >
                    <div className="mb-2 mb-md-0">
                      <p className="text-muted fs-14 mb-0">
                        {featuredJobdetails.addinfo}
                      </p>
                    </div>
                  </Col>
                )
              }
              {
                featuredJobdetails.addinfo2 && (
                  <Col >
                    <div className="mb-2 mb-md-0">
                      <p className="text-muted fs-14 mb-0">
                        {featuredJobdetails.addinfo2}
                      </p>
                    </div>
                  </Col>
                )
              }
              <Col >
                <div>
                  <div className="float-end">
                    <span
                      className={`badge ${featuredJobdetails.badgeclassName} fs-13 mt-1 d-block`}
                    >
                      {featuredJobdetails.type}
                    </span>
                    {(featuredJobdetails.badges || []).map((badgeInner, key) => (
                      <span
                        className={`badge ${badgeInner.badgeclassName} fs-13 mt-1 d-block`}
                        key={key}
                      >
                        {badgeInner.badgeName}
                      </span>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="p-3 bg-light">
            <Row>
              <Col md={9}>
                <div>
                  <p className="text-muted mb-0">
                    <span className="text-dark"> {featuredJobdetails.info_title} :</span>{" "}
                    {featuredJobdetails.info_desc ? (
                      <ExpandText text={featuredJobdetails.info_desc} maxLength={150} />
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
              </Col>

              <Col md={3}>
                <div className="text-start text-md-end">
                  <Link
                    to={featuredJobdetails.detailUrl}
                    data-bs-toggle="modal"
                    className="primary-link"
                  >
                    View Details<i className="mdi mdi-chevron-double-right"></i>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ))}
      {pageCount >= 1 && (
        <div className="pagination-controls mt-5 d-flex justify-content-center">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName="active"
            nextClassName="page-item"
            previousClassName="page-item"
            nextLinkClassName="page-link"
            previousLinkClassName="page-link"
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ImportRestrictions;
