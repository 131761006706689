import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Form, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobSearch from "../../Home/SubSection/JobSearch";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const JobGridHeader = ({ initialProduct = "", initialDestination = null, initialSearchType = null }) => {
  const [destination, setDestination] = useState(null); 
  const [countryOptions, setCountryOptions] = useState([]); 
  const [product, setProduct] = useState(initialProduct);
  const [searchType, setSearchType] = useState(null); 
  const navigate = useNavigate();

  const searchTypeOptions = [
    { value: "product", label: "Search by Product" },
    { value: "title", label: "Search by Title" }
  ];

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none"
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible"
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : undefined,
      transition: "top 0.1s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    })
  };

  useEffect(() => {
    if (countryOptions.length > 0) {
      if (initialDestination) {
        const foundDestination = countryOptions.find(opt => opt.value === initialDestination);
        setDestination(foundDestination || null); 
      }
    }
  }, [countryOptions, initialDestination]);

  useEffect(() => {
    if (initialSearchType) {
      const foundSearchType = searchTypeOptions.find(opt => opt.value === initialSearchType);
      setSearchType(foundSearchType || { value: "product", label: "Search by Product" });
    }
  }, [initialSearchType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (product && destination?.value && searchType?.value) {
      const searchParams = `/regulation-search?searchType=${searchType.value}&product=${encodeURIComponent(product)}&destination=${destination.value}`;
      navigate(searchParams);
    } else {
      toast.error("Please fill in all fields!");
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Form onSubmit={handleSubmit}>
        <Row className="g-2">
          <Col lg={12} md={12}>
            <div className="filter-search-form filter-border mt-1 mb-2 mt-md-0">
              <i className="uil uil-briefcase-alt"></i>
              <JobSearch
                value={product}
                onChange={(e) => setProduct(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="filter-search-form mt-3 mt-md-0">
              <i className="uil uil-search"></i>
                <Select
                  options={searchTypeOptions}
                  className="choices selectForm__inner"
                  styles={colourStyles}
                  value={searchType}
                  onChange={(selectedOption) => setSearchType(selectedOption)}
                  placeholder="Search Type"
                  isClearable
                />
            </div>
          </Col>
          <Col lg={5} md={6}>
            <div className="filter-search-form mt-3 mt-md-0">
              <i className="uil uil-location-point"></i>
              <CountryOptions
                onCountrySelect={setDestination}
                selectedCountry={destination}
                setCountryOptions={setCountryOptions}
                label={"Destination"}
              />
            </div>
          </Col>
          <Col lg={2} md={12}>
            <button type="submit" className="btn btn-primary w-100">
              <i className="uil uil-filter"></i> Filter
            </button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default JobGridHeader;
