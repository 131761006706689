import React from "react";
import SectionAPI from "./SectionAPI";
import PricingPageAPI from "./PricingPageAPI";
import Cta from "./Cta";

const Pricing = () => {
  document.title = "Pricing | Smart HS Codes - HS Code and Regulations Match ";
  return (
    <React.Fragment>
      <SectionAPI />
      <PricingPageAPI />
      <Cta />
    </React.Fragment>
  );
};

export default Pricing;
