// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";

// Create the context
const AuthContext = createContext();

// Provider component
export const AuthProvider = ({ children }) => {
  const {
    user,
    isAuthenticated,
    logout,
    isLoading,
    loginWithPopup,
    getAccessTokenSilently,
  } = useAuth0();

  const [isAuthLoading, setAuthLoading] = useState(true);
  const [userCredit, setUserCredit] = useState(0);
  const [userCreditUsed, setUserCreditUsed] = useState(0);
  const [isFetchingCredit, setIsFetchingCredit] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);
  const [isSubUser, setIsSubUser] = useState(false);
  const [userRegistered, setUserRegistered] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const tier = {
    "1": "Starter",
    "2": "Premium",
    "10": "Premium",
  }

  const color = {
    "1": "success",
    "2": "info",
    "10": "info",
  }

  const burnUserCredit = async () => {
    const maxRetries = 5;
    let retryCount = 0;

    if (!isAuthenticated || !userSubscription) {
      toast.error("Please sign in to start the free trial with search credits.");
      return false;
    } else if (!userSubscription) {
      toast.warn("Please wait for subscription details to be loaded");
      return false;
    }

    const payload = {
      subId: userSubscription?.data?.sub_id,
      customerId: userSubscription?.data?.customer_id,
      subUserId: isSubUser ? user.email : null
    };

    while (retryCount < maxRetries) {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`https://mtech-api.com/users-smart-hs/subscription/useCredit`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          setUserCredit(prevCredit => prevCredit - 1);
          setUserCreditUsed(prevCreditUsed => prevCreditUsed + 1);
          return true;
        } else {
          const errorData = await response.text();
          if (errorData === "No trial credits left") {
            toast.error("No trial credits left. Please subscribe to continue.");
            return false;
          } else if (retryCount + 1 === maxRetries) {
            toast.error("You have no credits left.");
            return false;
          }
        }
      } catch (error) {
        console.error(`Attempt ${retryCount + 1} failed:`, error);
      }
      retryCount++;
      await new Promise(resolve => setTimeout(resolve, 1000)); // Retry delay
    }
  };

  useEffect(() => {
    if (!isLoading) {
      console.log(user);
      setAuthLoading(false);
    }
  }, [isLoading]);
  // Function to handle login
  const handleLogin = async () => {
    try {
      setAuthLoading(true);
      await loginWithPopup();
    } catch (error) {
      toast.error("Login failed", error);
    } finally {
      setAuthLoading(false);
    }
  };

  // Fetch subscription details
  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (isAuthenticated && user) {
        try {
          setIsFetchingCredit(true);
          const token = await getAccessTokenSilently();
          const [response, userResponse, responseApi] = await Promise.all([
            fetch(
              `https://mtech-api.com/users-smart-hs/subscription/current?userid=${user?.email}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
            fetch(
              `https://mtech-api.com/users-smart-hs?userid=${user?.email}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
            fetch(
              `https://mtech-api.com/users-smart-hs/subscription-api/current?userid=${user?.email}`,
              {
                method: "GET",
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            ),
          ]);
  
          // Fetch and set user information
          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUserInfo(userData);
          }
  
          let subscriptionData = null;
          let userCredit = 0;
          let userCreditUsed = 0;
  
          // Case 1: User has both User and API subscriptions
          if (response.ok && responseApi.ok) {
            const data = await response.json();
            const apiData = await responseApi.json();
            userCredit = data.data.credits_purchased - data.data.credits_used;
            subscriptionData = {
              ...data,
              detail: userInfo,
              tierName: tier[data.data.tier],
              tierColor: color[data.data.tier],
              api: { ...apiData, tierName: tier[apiData.data.tier], tierColor: color[apiData.data.tier] },
            };
            userCreditUsed = parseInt(subscriptionData?.data?.credits_used);
          } 
          // Case 2: Only User subscription exists
          else if (response.ok) {
            const data = await response.json();
            userCredit = data.data.credits_purchased - data.data.credits_used;
  
            subscriptionData = {
              ...data,
              detail: userInfo,
              tierName: tier[data.data.tier],
              tierColor: color[data.data.tier],
            };
            userCreditUsed = parseInt(subscriptionData?.data?.credits_used);
          } 
          // Case 3: Only API subscription exists, check sub-user status
          else if (responseApi.ok) {
            const apiData = await responseApi.json();
            subscriptionData = {
              detail: userInfo,
              tierName: tier[apiData.data.tier],
              tierColor: color[apiData.data.tier],
              api: { ...apiData, tierName: tier[apiData.data.tier], tierColor: color[apiData.data.tier] },
            };
          }

          if (!response.ok) {
            // Sub-user check
            const [checkSubResponse] = await Promise.all([
              fetch(
                `https://mtech-api.com/users-smart-hs/subscription/check_sub_user?user_email=${user?.email}`,
                {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);
  
            if (checkSubResponse.ok) {
              const checkSubData = await checkSubResponse.json();
  
              // If sub-user, fetch main user subscription data
              if (checkSubData?.data?.main_sub_id) {
                const [mainUserResponse] = await Promise.all([
                  fetch(
                    `https://mtech-api.com/users-smart-hs/subscription/main_user_credits?sub_id=${checkSubData.data.main_sub_id}`,
                    {
                      method: "GET",
                      headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  ),
                ]);
  
                if (mainUserResponse.ok) {
                  const mainUserData = await mainUserResponse.json();
                  subscriptionData ={
                    ...mainUserData,
                    tierName: tier[mainUserData.data.tier],
                    tierColor: color[mainUserData.data.tier],
                  };
                  userCredit = mainUserData.data.credits_purchased - mainUserData.data.credits_used;
  
                  userCreditUsed = parseInt(checkSubData.data.credits_used, 10);
                  setIsSubUser(true);
                }
              }
            }
          }
  
          // Set subscription data and user credit
          setUserSubscription(subscriptionData);
          setUserCredit(userCredit);
          setUserCreditUsed(userCreditUsed || 0);
  
          // Register user if needed
          if (!userResponse.ok) {
            const [userRegisterResponse] = await Promise.all([
              fetch(
                `https://mtech-api.com/users-smart-hs/stripe/customer/create?email=${user?.email}&name=${user?.name}`,
                {
                  method: "GET",
                  headers: {
                    "content-type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                }
              ),
            ]);
            if (userRegisterResponse.ok) {
              setUserRegistered(prev => !prev);
              setUserInfo(await userRegisterResponse.json());
            }
          }
        } catch (error) {
          setUserCredit(0);
        } finally {
          setIsFetchingCredit(false);
        }
      }
    };
  
    fetchSubscriptionDetails();
  }, [isAuthenticated, user, getAccessTokenSilently, userRegistered]);
  

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        logout,
        isAuthLoading,
        userCredit,
        userCreditUsed,
        handleLogin,
        isFetchingCredit,
        userSubscription,
        getAccessTokenSilently,
        burnUserCredit,
        isSubUser,
        userInfo
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for easy access to AuthContext
export const useAuth = () => useContext(AuthContext);
