import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { isEU } from "Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import { useAuth } from "Utils/AuthContext";

const CandidateDetails = () => {
  document.title = "HS Code Match Import Duty and Regulations";
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [hasBurnedCredit, setHasBurnedCredit] = useState(false);
  const location = useLocation();

  const { isAuthenticated, userCredit, burnUserCredit } = useAuth();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      origin: searchParams.get('origin'),
    };
  };

  const { product, destination, origin } = getQueryParams();

  const fetchHSCodeData = async () => {
    if (!product || !destination || !origin) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const baseUrl = `https://mtech-api.com/web-app/`;
    const code6 = product.slice(0, 6);

    // Helper function to update each part of the data as it arrives
    const updateData = (key, value) => {
      setData(prevData => ({
        ...prevData,
        [key]: value,
        metadata: { product, destination, origin },
      }));
    };

    // Define all the requests separately and update the data for each as they complete
    const requests = [
      fetchEnc(`${baseUrl}smart-hs?product=${product}&category=${destination}`)
        .then(res => res.json())
        .then(result => updateData('hs', result))
        .catch(err => console.error("Smart HS Error: ", err)),

      fetchEnc(`${baseUrl}import_duty?q=${code6}&dest_country=${destination}&origin_country=${origin}`)
        .then(res => res.json())
        .then(result => updateData('importDuty', result))
        .catch(err => console.error("Import Duty Error: ", err)),

      fetchEnc(`${baseUrl}import-export-restrictions?product=${code6}&reporter=${destination}`)
        .then(res => res.json())
        .then(result => updateData('qr', result))
        .catch(err => console.error("QR Error: ", err)),

      fetchEnc(`${baseUrl}duty-measures?reporter=${destination}&exporter=${origin}&product=${code6}`)
        .then(res => res.json())
        .then(result => updateData('antidumping', result))
        .catch(err => console.error("Anti Dumping Error: ", err)),

      fetchEnc(`${baseUrl}product-requirements?product=${code6}&country=${destination}`)
        .then(res => res.json())
        .then(result => updateData('pr', result))
        .catch(err => console.error("PR Error: ", err)),

      fetchEnc(`${baseUrl}import-licensing/country-regulations?country=${destination}&product=${code6}`)
        .then(res => res.json())
        .then(result => updateData('importLicensing', result))
        .catch(err => console.error("Import Licensing Error: ", err)),
    ];

    // Additional request if destination is in the EU
    if (isEU(destination)) {
      requests.push(
        fetchEnc(`${baseUrl}eu-taric?product=${code6}&country=${origin}`)
          .then(res => res.json())
          .then(result => updateData('euTaric', result))
          .catch(err => console.error("EU Taric Error: ", err))
      );
    }

    // Await all requests to remove the loading state when they finish
    await Promise.allSettled(requests);
    setLoading(false);
  };

  useEffect(() => {
    fetchHSCodeData();
  }, [location]);

  useEffect(() => {
    if (isAuthenticated && userCredit > 0 && !hasBurnedCredit) {
      burnUserCredit(userCredit);
      setHasBurnedCredit(true);
    }
  }, [isAuthenticated, userCredit, burnUserCredit, hasBurnedCredit]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <LeftSideContent data={data} hasBurnedCredit={hasBurnedCredit} />
            <RightSideContent data={data} hasBurnedCredit={hasBurnedCredit} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CandidateDetails;
