import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { TranslationContext } from "./TranslationContext";

// Context to track the translation state

export const useTranslation = () => useContext(TranslationContext);

const LinkWrapper = ({ to, children, ...props }) => {
  const { isTranslated } = useTranslation();

  return isTranslated ? (
    <a href={to} {...props}>
      {children}
    </a>
  ) : (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default LinkWrapper;
