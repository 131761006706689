import React, { useState, useEffect } from "react";
import Section from "./Section";
import { Container } from "react-bootstrap"; // Assuming you are using react-bootstrap

const TermsAndConditions = () => {
  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    document.title = "Terms & Conditions | Smart HS Codes - HS Code and Regulations Match";

    const fetchPdf = async () => {
      try {
        const pdfResponse = await fetch('/Terms.pdf');
        const pdfBlob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      }
    };

    fetchPdf();
  }, []);

  return (
    <React.Fragment>
      <Section />
      <Container>
        {pdfUrl ? (
          <iframe src={pdfUrl} width="100%" height="1200px" title="Terms and Conditions PDF" />
        ) : (
          <p>Loading PDF...</p>
        )}
      </Container>
    </React.Fragment>
  );
};

export default TermsAndConditions;
