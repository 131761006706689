import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card, CardBody } from "reactstrap";
import RainbowButton from "Utils/RainbowButton";

const formatKey = (key) => {
  return key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const renderData = (data) => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      return data.map((item) => renderData(item)).join(", ");
    } else {
      return Object.keys(data)
        .map((key) => `${formatKey(key)}: ${renderData(data[key])}`)
        .join("| ");
    }
  }
  return data ? String(data) : "N/A";
};

const RightSideContent = ({ data, isImportDuty, hasBurnedCredit }) => {
  const [summary, setSummary] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  const summarizeText = async (text, limiter = true) => {
    if (text.length < 500 && limiter) {
      return "";
    }

    try {
      const response = await fetch("https://mtech-api.com/web-app/summarize", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-user-email": "no.reply.moaah@gmail.com",
          "x-user-sub": "KzNZJAK4TamQrHX34EayryukBO9sxjam",
        },
        body: JSON.stringify({ text }),
      });
      const result = await response.json();
      return result.text || "";
    } catch (error) {
      return "";
    }
  };

  const handleSummarize = async () => {
    if (!hasBurnedCredit) {
      toast.error("Please Subscribe to Use This Function");
      setTimeout(() => {
        window.location.href = "/pricing";
      }, 5000);
      return
    }

    setIsGenerating(true);
    let summaryText = "";
    if (isImportDuty) {
      for (const item of data.RulesOfOriginProvisionData) {
        const summaryPart = await summarizeText(item.ProvisionLegalExtract);

        if (summaryPart) {
          summaryText += `${summaryPart}. (summary of <b>Provision Legal Extract of ${item.ProvisionName}) (${item.ProvisionName} (${item.ProvisionValue}))</b><br /> <br />`;
          setSummary((prev) => prev + (summaryPart ? `${summaryPart}. (summary of <b>Provision Legal Extract of ${item.ProvisionName}) (${item.ProvisionName} (${item.ProvisionValue}))</b><br /> <br />` : ""));
        }
      }
    } else {
      const dataParts = renderData(data).split("| ");

      for (const text of dataParts) {
        const summaryPart = await summarizeText(text);
        if (summaryPart) {
          summaryText += `${summaryPart}.<br /> <br />`;
          setSummary((prev) => prev + (summaryPart ? `${summaryPart}.<br /> <br />` : ""));
        }
      }

      if (summaryText === "") {
        let unifiedText = "";

        for (const text of dataParts) {
          unifiedText += `${text}. `;
        }

        const summaryPart = await summarizeText(unifiedText, false);
        summaryText += (summaryPart ? `${summaryPart}.<br /> <br />` : "");
      }
    }

    if (summaryText === "" || summaryText.length < 80) {
      summaryText = "Data is too short to generate summary.";
    }

    setSummary(summaryText);
    setIsGenerating(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">AI Generated Summary</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <div className="ms-3">
                    {summary && (
                      <p className="text-muted mb-0" dangerouslySetInnerHTML={{ __html: summary }} />
                    )}

                    {(isGenerating || !summary) && (
                      <RainbowButton
                        text={isGenerating ? "Generating..." : "Generate"}
                        onClick={handleSummarize}
                        disabled={isGenerating}
                      />
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
