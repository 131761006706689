import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import axios from "axios";
const { ValueContainer, Placeholder } = components;

const CountryOptions = ({ onCountrySelect, selectedCountry = null, setCountryOptions, label }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCountries = async () => {
      const cachedCountriesFromLocalStorage = localStorage.getItem("cachedCountries");
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      const expirationDate = localStorage.getItem("countryOptionsExpiration");
      const isCacheExpired = expirationDate && Date.now() > parseInt(expirationDate, 10);
      if (cachedCountriesFromLocalStorage && !isCacheExpired) {
        setOptions(JSON.parse(cachedCountriesFromLocalStorage));
        setLoading(false);
        setCountryOptions(JSON.parse(cachedCountriesFromLocalStorage)); // Pass the country options back to JobFilters
      } else {
        try {
          const response = await axios.get("https://mtech-api.com/web-app/smart-hs/getHsCountries");
          const countryOptions = response.data.data.map(country => ({
            value: country.api_code,
            label: country.country
          }));
          localStorage.setItem("cachedCountries", JSON.stringify(countryOptions));
          localStorage.setItem("countryOptionsExpiration", Date.now() + oneDayInMilliseconds);
          setOptions(countryOptions);
          setLoading(false);
          setCountryOptions(countryOptions); // Pass the country options back to JobFilters
        } catch (error) {
          console.error("Error fetching country data", error);
        }
      }
    };

    fetchCountries();
  }, []);

  const colourStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 35px",
      margin: "-16px 0 0 -45px",
      borderRadius: "0",
      outline: "none"
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow: "visible"
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: state.hasValue || state.selectProps.inputValue ? -15 : undefined,
      transition: "top 0.1s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
    })
  };

  const handleChange = (selectedOption) => {
    onCountrySelect(selectedOption);
  };

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <p>Loading countries...</p>
      ) : (
        <Select
          options={options}
          className="choices selectForm__inner"
          value={selectedCountry ? selectedCountry : null}
          onChange={handleChange}
          styles={colourStyles}
          placeholder={label}
          isClearable
          components={{
            ValueContainer: CustomValueContainer
          }}
        />
      )}
    </React.Fragment>
  );
};

export default CountryOptions;