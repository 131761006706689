import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";

const Counter = () => {
  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
        <Row>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={183}
                      duration={2}
                      className="counter counter_custom mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Countries Coverage</h6>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={1.5}
                      duration={2}
                      decimals={1}
                      className="counter mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Seconds Latency </h6>

                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={6}
                      duration={2}
                      className="counter mb-0"
                    ></CountUp>
                    <h6 className="fs-16 mt-3">Years of Development </h6>
                  </div>
                </div>
              </Col>
              <Col lg={3} md={6}>
                <div className="counter-box mt-3">
                  <div className="counters counter_custom text-center">
                    <CountUp
                      end={535}
                      duration={2}
                      className="counter mb-0"
                    ></CountUp>

                    <h6 className="fs-16 mt-3">Member Companies</h6>
                  </div>
                </div>
              </Col>
        </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Counter;
