import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ConditionalWrapper, countryList } from "../../../Utils/Utils";
import RightSideContent from "./RightSideContent";
import BlurRandomTextWrapper from "Utils/BlurRandomTextWrapper";

const findByCode = (code, data, metadata) => {
  let allItems;
  if (metadata?.product !== "top 50") {

    allItems = [
      ...data.antidumping_investigations,
      ...data.antidumping_measures,
      ...data.countervailing_investigations,
      ...data.countervailing_measures,
    ];
  } else {
    allItems = [
      ...data.data
    ];
  }

  return allItems.find((item) => item.code === code);
};

const findCountriesInData = (data) => {
  let foundCountries = [];

  const search = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => search(item));
      } else {
        Object.values(obj).forEach((value) => search(value));
      }
    } else if (typeof obj === "string") {
      countryList().forEach((country) => {
        if (obj.includes(country.country)) {
          foundCountries.push(country.country);
        }
      });
    }
  };

  search(data);
  return [...new Set(foundCountries)];
};

const formatKey = (key) => {
  return key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const renderData = (data, ignoreList = ["footnotes"]) => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderData(item, ignoreList)}</li>
          ))}
        </ul>
      );
    } else {
      const keys = Object.keys(data).filter((key) => !ignoreList.includes(key));
      return (
        <ul>
          {keys.map((key) => (
            <li key={key}>
              <strong>{formatKey(key)}: </strong>
              {renderData(data[key], ignoreList)}
            </li>
          ))}
        </ul>
      );
    }
  }
  return <span dangerouslySetInnerHTML={{ __html: data ? data.toString() : "N/A" }}></span>;
};

const JobDetailsDescription = ({ data, isLoading, hasBurnedCredit }) => {
  let title, dataRef, dataSummarize, citedCountries;
  let isImportDuty = false;

  if (!isLoading) {
    if (data.metadata.type === 'Antidumping Measures' || data.metadata.type === 'Antidumping Investigations' || data.metadata.type === 'Countervailing Measures' || data.metadata.type === 'Countervailing Investigations') {
      dataRef = data?.data && findByCode(data.metadata.id, data.data, data.metadata);
      title = data.metadata.type + " - " + dataRef?.subject_product + " - " + dataRef?.hs_section_label;
      citedCountries = findCountriesInData(dataRef);
      dataSummarize = dataRef;
    } else if (data.metadata.type === "Import Licensing") {
      dataRef = data?.data?.data?.find((item) => item.title === data.metadata.id);
      title = dataRef?.title;
      citedCountries = findCountriesInData(dataRef);
      dataSummarize = dataRef;
    } else if (data.metadata.type === "Import Export Restriction") {
      dataRef = data?.data?.data?.find((item) => item.id === data.metadata.id);
      title = dataRef?.general_description;
      citedCountries = findCountriesInData(dataRef);
      dataSummarize = dataRef;
    } else if (data.metadata.type === "Product Requirement") {
      dataRef = data?.data?.ProductRequirementsDetails?.find(
        (item) => item.documentSymbol === data.metadata.id
      );
      title = dataRef?.titlePlain;
      citedCountries = findCountriesInData(dataRef);
      dataSummarize = dataRef;
    } else if (data.metadata.type === "Import Duty") {
      dataRef = data?.data?.FTAImportDuty?.find((item, index) =>
        index === 0 ||
        item.find(
          (subItem, subIndex) =>
            (subItem.FTANameFull || subItem.FTAName) ===
            (data.metadata.id || data.metadata.FTAName)
        )
      )?.find(
        (subItem, subIndex) =>
          (subItem.FTANameFull || subItem.FTAName) ===
          (data.metadata.id || data.metadata.FTAName)
      );
      title = dataRef?.FTANameFull || dataRef?.FTAName;
      citedCountries = findCountriesInData(dataRef);
      dataSummarize = dataRef;
      isImportDuty = true;
    } else if (data.metadata.type === "EU Commission") {
      dataRef = data?.data?.[0];
      title = dataRef?.title;
      citedCountries = [];
      dataSummarize = dataRef;
      data.countryFlag = `https://flagcdn.com/w80/eu.jpg`
    }
  }

  return (
    <React.Fragment>
      <Col lg={8}>

        <Card className="job-detail overflow-hidden">
          <CardBody className="p-4">
            <div>
              <Row>
                <Col md={10}>
                  <h5 className="mb-1">
                    Title : {isLoading ? <Skeleton width={150} /> : title}
                  </h5>
                </Col>
                <Col lg={2}>
                  {isLoading ? (
                    <Skeleton circle={true} height={40} width={40} />
                  ) : (
                    <img
                      src={data?.countryFlag}
                      height="40"
                      alt=""
                      className="logo-dark"
                    />
                  )}
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <Row className="g-2">
                <Col lg={6}>
                  <div className="border rounded-start p-3">
                    <p className="text-muted mb-0 fs-13">Category </p>
                    <p className="fw-medium fs-15 mb-0">
                      {isLoading ? <Skeleton width={100} /> : data.metadata.type}
                    </p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0"> Country </p>
                    <p className="fw-medium mb-0">
                      {isLoading ? (
                        <Skeleton width={100} />
                      ) : (
                        `Member Name: ${data?.countryName}`
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
              <div className="mt-3">
                <p className="fw-medium fs-15 mb-0">Cited Countries </p>
              </div>
              <div className="mt-1 d-flex flex-wrap align-items-start gap-1">
                {isLoading ? (
                  <Skeleton count={3} width={80} height={30} />
                ) : citedCountries?.length > 0 ? (
                  citedCountries.map((country, index) => (
                    <span key={index} className="badge bg-primary">
                      {country}
                    </span>
                  ))
                ) : (
                  <span className="text-muted">No cited countries found</span>
                )}
              </div>
            </div>
            <ConditionalWrapper
              condition={hasBurnedCredit}
              wrapper={(children) => <BlurRandomTextWrapper>{children}</BlurRandomTextWrapper>}
            >
              <div className="mt-4">
                <h5 className="mb-3">Regulation Details </h5>
                <div className="job-detail-desc">
                  {isLoading ? <Skeleton count={5} /> : renderData(dataRef)}
                </div>
              </div>
            </ConditionalWrapper>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4} className="mt-4 mt-lg-0">
        {isLoading ?
          <Card className="job-overview">
            <CardBody className="p-4">
              <Skeleton width={250} />
              <Skeleton width={200} />
            </CardBody>
          </Card>
          : <RightSideContent data={dataSummarize} isImportDuty={isImportDuty} hasBurnedCredit={hasBurnedCredit}/>}
      </Col>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
