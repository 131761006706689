import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import JobGridHeader from "./JobGridHeader";
import Section from "./Section";
import ImportRestrictions from "../../Home/JobList/ImportRestriction";
import { useLocation } from "react-router-dom";
import { isEU } from "Utils/Utils";
import fetchEnc from "Utils/FetchEnc";

const JobGrid = () => {
  document.title = "Regulation Search | Smart HS Codes - HS Code and Regulations Match";

  const [loading, setLoading] = useState(true);
  const [candidates, setData] = useState([]);
  const location = useLocation();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      searchType: searchParams.get('searchType') || "product", // Default to "product"
      page: searchParams.get('page'),
    };
  };

  const { product, destination, searchType, page } = getQueryParams();

  const fetchHSCodeData = async () => {
    if (!product || !destination || !searchType) {
      try {
        const qrUrl = `https://mtech-api.com/web-app/import-export-restrictions-latest`;
        const antiDumpingUrl = `https://mtech-api.com/web-app/duty-measures-latest`;
        const prUrl = `https://mtech-api.com/web-app/product-requirements-latest`;
        const importLicensingUrl = `https://mtech-api.com/web-app/import-licensing/country-regulations-latest`;

        const [qrData, antiDumpingData, prData, importLicensingData] = await Promise.all([
          fetchEnc(qrUrl).then(res => res.json()),
          fetchEnc(antiDumpingUrl).then(res => res.json()),
          fetchEnc(prUrl).then(res => res.json()),
          fetchEnc(importLicensingUrl).then(res => res.json()),
        ]);

        setData([{
          qr: qrData,
          antidumping: antiDumpingData,
          pr: prData,
          importLicensing: importLicensingData,
          metadata: { product: "top 50", destination: "top 50"  }
        }]);
      } catch (error) {
        console.error("Error fetching latest data: ", error);
      } finally {
        setLoading(false);
      }
    } else {

      setData([]); // Clear previous data
      setLoading(true); // Show the loading spinner at the start
      let dataUrl = `https://mtech-api.com/web-app/`

      if (searchType === "title") {
        // Direct API calls using the product title
        try {
          let qrUrl = `${dataUrl}import-export-restrictions?title=${product}&reporter=${destination}`;
          let antiDumpingUrl = `${dataUrl}duty-measures?reporter=${destination}&title=${product}`;
          let prUrl = `${dataUrl}product-requirements?title=${product}&country=${destination}`;
          let importLicensingUrl = `${dataUrl}import-licensing/country-regulations?country=${destination}&title=${product}`;

          const [qrData, antiDumpingData, prData, importLicensingData] = await Promise.all([
            fetchEnc(qrUrl).then(res => res.json()),
            fetchEnc(antiDumpingUrl).then(res => res.json()),
            fetchEnc(prUrl).then(res => res.json()),
            fetchEnc(importLicensingUrl).then(res => res.json()),
          ]);

          setData([{
            qr: qrData,
            antidumping: antiDumpingData,
            pr: prData,
            importLicensing: importLicensingData,
            metadata: { product, destination, searchType }
          }]);
        } catch (error) {
          console.error("Error fetching data by title: ", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Fetch HS codes and make API calls based on HS codes
        let hsCodeUrl = `${dataUrl}smart-hs?product=${product}&category=${destination}`;
        try {
          const response = await fetchEnc(hsCodeUrl);
          const data = await response.json();
          const hsCodes = data.data.six_digit_codes.filter(code => code.code6);

          if (hsCodes.length === 0) {
            setLoading(false); // Hide spinner if no HS codes found
            return;
          }

          // Fetch all HS code-related data
          const allRequests = hsCodes.map(async (code) => {
            const qrUrl = `${dataUrl}import-export-restrictions?product=${code.code6}&reporter=${destination}`;
            const antiDumpingUrl = `${dataUrl}duty-measures?reporter=${destination}&product=${code.code6}`;
            const prUrl = `${dataUrl}product-requirements?product=${code.code6}&country=${destination}`;
            const importLicensingUrl = `${dataUrl}import-licensing/country-regulations?country=${destination}&product=${code.code6}`;

            const requests = [
              fetchEnc(qrUrl).then(res => res.json()).catch(() => null),
              fetchEnc(antiDumpingUrl).then(res => res.json()).catch(() => null),
              fetchEnc(prUrl).then(res => res.json()).catch(() => null),
              fetchEnc(importLicensingUrl).then(res => res.json()).catch(() => null),
            ];

            if (isEU(destination)) {
              requests.push(fetchEnc(`${dataUrl}eu-taric?product=${product}`).then(res => res.json()).catch(() => null));
            }

            try {
              const [qrData, antiDumpingData, prData, importLicensingData, euTaricData] = await Promise.all(requests);

              setData( prev => [
                ...prev,
                {
                  qr: qrData,
                  antidumping: antiDumpingData,
                  pr: prData,
                  importLicensing: importLicensingData,
                  euTaric: euTaricData,
                  hs: code,
                  metadata: { product, destination, searchType },
                }
              ]);
            } catch (err) {
              console.error("Error fetching data for code: ", code.code6, err);
            }
          });

          // Wait for all requests to complete before hiding the spinner
          await Promise.all(allRequests);
        } catch (error) {
          console.error("Error fetching HS Code data: ", error);
        } finally {
          setLoading(false); // Hide spinner once all requests are done
        }
      }      
    }
  };

  useEffect(() => {
    fetchHSCodeData();
  }, [location]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <JobGridHeader
            initialProduct={product}
            initialDestination={destination}
            initialSearchType={searchType}
          />
          <Row>
            <Col lg={12}>
              {loading && (
                <div className="d-flex justify-content-center my-5">
                  <Spinner color="primary" />
                </div>
              )}
              <ImportRestrictions candidates={candidates} metadata={{page}}/>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobGrid;
