import React, { useState, useEffect } from 'react';
import { useAuth } from 'Utils/AuthContext';

const DeleteButton = () => {
    const {
        user,
        isAuthenticated,
        logout,
        isAuthLoading,
        handleLogin,
        userCredit,
        isFetchingCredit,
        userSubscription,
        getAccessTokenSilently,
    } = useAuth(); // Use context for auth and subscription logic

    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [countdown, setCountdown] = useState(10);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        let countdownInterval;
        if (isConfirmVisible && countdown > 0) {
            countdownInterval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        }
        if (countdown <= 0) {
            setIsConfirmVisible(false);
            setCountdown(10);
        }

        return () => clearInterval(countdownInterval);
    }, [isConfirmVisible, countdown]);

    const handleFakeDeleteClick = () => {
        setIsConfirmVisible(true);
    };

    const handleDeleteClick = async () => {
        clearInterval();
        setIsDeleting(true);

        const email = user?.email;
        try {
            await deleteStripeUser(email);
            await deleteAuth0User(email);
        } catch (error) {
            console.error('Error deleting user:', error);
        } finally {
            setIsDeleting(false);
            logout({ returnTo: window.location.origin })
        }
    };

    const deleteStripeUser = async (email) => {
        console.log('deleteStripeUser');
        const token = await getAccessTokenSilently();
        const deleteUrl = `https://mtech-api.com/users-smart-hs/user/stripe/delete?email=${user?.email}`;
        const response = await fetch(deleteUrl, {
            method: 'GET',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) {
            console.error('Failed to delete Stripe user');
        }
        return response.json();
    };

    const deleteAuth0User = async (email) => {
        console.log('deleteAuth0User');
        const token = await getAccessTokenSilently();
        const response = await fetch('https://mtech-api.com/users-smart-hs/delete-auth0-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ email }),
        });
        if (!response.ok) {
            console.error('Failed to delete Auth0 user');
        }
        return response.json();
    };

    return (
        <div>
            {!isConfirmVisible && (
                <button
                    id="fake_delete_btn"
                    className="btn btn-danger mb-2"
                    onClick={handleFakeDeleteClick}
                >
                    Delete
                </button>
            )}
            {isConfirmVisible && !isDeleting && (
                <button
                    id="delete_btn"
                    className="btn btn-danger mb-2"
                    onClick={handleDeleteClick}
                >
                    Are you sure? (<span id="countdown">{countdown}</span>)
                </button>
            )}
            {isDeleting && (
                <button id="delete_btn" className="btn btn-danger mb-2" disabled>
                    <span
                        className="spinner-border spinner-border-sm text-dark"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    Deleting...
                </button>
            )}
        </div>
    );
};

export default DeleteButton;
