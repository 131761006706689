import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Row, Input, Label } from "reactstrap";
import contactImage from "../../assets/images/contact.png";

const ContactContent = () => {
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [messageSent, setMessageSent] = useState(false); // State to show success message

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setLoading(true); // Show spinner
    setMessageSent(false); // Reset message state

    const form = event.target;
    const formData = new FormData(form);
    formData.append("access_key", "55e15ffb-076f-4229-ba03-096e61207b70"); // Add your access key
    formData.append("from_name", "mtech-app.com"); // Add from_name

    fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          setMessageSent(true); // Show thank you message
        } else {
          throw new Error("Form submission failed.");
        }
      })
      .catch(() => {
        setMessageSent(false);
        alert("Failed to send message. Please try again.");
      })
      .finally(() => {
        setLoading(false); // Hide spinner after request completes
        setTimeout(() => setMessageSent(false), 3000); // Clear message after 3 seconds
      });
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="align-items-center mt-5">
            <Col lg={6}>
              <div className="section-title mt-4 mt-lg-0">
                <h3 className="title">Contact Us</h3>
                <p className="text-muted">
                  We will process your queries within 24-48 hours. Please feel free to reach out directly via our email (contact@smart-hscodes.com).
                </p>
                <Form onSubmit={handleSubmit} className="contact-form mt-4" id="myForm">
                  <span id="error-msg"></span>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="nameInput" className="form-label">
                          Name
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter your name"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">
                          Email
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Enter your email"
                          required
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="mb-3">
                        <Label htmlFor="subjectInput" className="form-label">
                          Subject
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          name="subject"
                          placeholder="Enter your subject"
                          required
                        />
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label htmlFor="messageInput" className="form-label">
                          Your Message
                        </Label>
                        <textarea
                          className="form-control"
                          placeholder="Enter your message"
                          name="message"
                          rows="3"
                          required
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{" "}
                          Sending...
                        </>
                      ) : messageSent ? (
                        <>
                          Thank you! We will contact you shortly <i className="uil uil-check-circle ms-1"></i>
                        </>
                      ) : (
                        <>
                          Send Message <i className="uil uil-message ms-1"></i>
                        </>
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col lg={5} className="ms-auto order-first order-lg-last">
              <div className="text-center">
                <img src={contactImage} alt="" className="img-fluid" />
              </div>
              <div className="mt-4 pt-3">
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">71 Ayer Rajah Crescent, #02-15, Singapore 139951</p>
                  </div>
                </div>
                <div className="d-flex text-muted align-items-center mt-2">
                  <div className="flex-shrink-0 fs-22 text-primary">
                    <i className="uil uil-envelope"></i>
                  </div>
                  <div className="flex-grow-1 ms-2">
                    <p className="mb-0">contact@smart-hscodes.com</p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="map">
        <iframe
          title="maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.796636858207!2d103.78463930959799!3d1.2966671986855585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1be3ceb330eb%3A0x8ea0096bf2e98a99!2sPIER71!5e0!3m2!1sen!2ssg!4v1728628410299!5m2!1sen!2ssg"
          height="350"
          style={{ border: `0`, width: `100%` }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </React.Fragment>
  );
};

export default ContactContent;
