import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import CandidateDetails from "./CandidateDetails";
import JobFilters from "./JobFilters";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { countryCode, countryFlag, countryName } from "../../../Utils/Utils";
import fetchEnc from "Utils/FetchEnc";

const CandidateList = () => {
  document.title = "HS Code Match Search Results";

  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const location = useLocation();

  // Helper function to get query params
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      origin: searchParams.get('origin'),
      page: searchParams.get('page'),
    };
  };

  let { product, destination, origin, page } = getQueryParams();



  const fetchHSCodeData = async () => {

    if (!product || !destination || !origin) {
      setLoading(false);
      return;
    }

    // Clear previous candidates before fetching new data
    setCandidates([]);
    setLoading(true); // Set loading to true before fetching

    // First API Call
    const firstApiUrl = `https://mtech-api.com/web-app/smart-hs?product=${product}&category=${destination}`;
    try {
      const response = await fetchEnc(firstApiUrl);
      const data = await response.json();
      const hsCodes = data.data.national_tariff_lines.filter(code => code.code);
      hsCodes.forEach(code => {
        code.origin_code = origin;
        code.country_flag_url = countryFlag(countryName(destination), 'w80');
      })
      setCandidates(prevCandidates => [...prevCandidates, ...hsCodes]);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false); // Set loading to false in case of error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHSCodeData();
  }, [location]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {/* Pass the query parameters to JobFilters */}
          <JobFilters
            initialProduct={product}
            initialDestination={destination}
            initialOrigin={origin}
          />
          <Row>
            <Col lg={12}>
              {loading && (
                <div className="d-flex justify-content-center my-5">
                  <Spinner color="primary" />
                </div>
              )}
              <CandidateDetails candidates={candidates} metadata={{ product, destination, origin, page }} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CandidateList;
