import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Card, Col, Input, Row, CardBody } from "reactstrap";
import axios from "axios";
import lightLogo from "../../assets/images/logo-light.png";
import darkLogo from "../../assets/images/logo-dark.png";
import signUpImage from "../../assets/images/auth/sign-up.png";
import { Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import configJson from "../../auth_config.json";
import { toast } from "react-toastify";

const SignUp = () => {
  document.title = "Sign Up | Smart HS Codes - HS Code and Regulations Match ";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // For redirection

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      // Auth0 Signup endpoint
      try {
        const response = await axios.post(`${configJson.domain}/dbconnections/signup`, {
          client_id: configJson.clientId, // Replace with your Auth0 Client ID
          email: email,
          password: password,
          connection: "Username-Password-Authentication" // Your Auth0 database connection name
        });

        toast.success("Registered successfully!");
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          if (data.name === "BadRequestError" && data.code === "invalid_signup") {
            toast.error(`Email ${email} is already registered.`);
          }
        }
      }

      // Authenticate the user automatically after registration
      const loginResponse = await axios.post(`${configJson.domain}/oauth/token`, {
        grant_type: "password",
        username: email,
        password: password,
        audience: configJson.audience, // Optional: If you use API audience for your app
        client_id: configJson.clientId, // Your Auth0 client ID
        client_secret: "", // Your Auth0 client secret
        scope: "openid profile email" // Basic scopes
      });

      // Assuming the response contains the access token
      const accessToken = loginResponse.data.access_token;
      localStorage.setItem("accessToken", accessToken); // Save the token in localStorage (or a suitable method)

      // Redirect to homepage after 3 seconds
      setTimeout(() => {
        navigate("/");
      }, 3000);

      setIsLoading(false);
    } catch (error) {
      console.error("Sign up error:", error);
      setError("Error signing up. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="align-items-center">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img src={lightLogo} alt="" className="logo-light" />
                              <img src={darkLogo} alt="" className="logo-dark" />
                            </Link>
                            <div className="mt-5">
                              <img src={signUpImage} alt="" className="img-fluid" />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 text-white">
                            <div className="w-100">
                              <div className="text-center">
                                <h5>Let's Get Started</h5>
                                <p className="text-white-70">
                                  Sign Up to start getting access to all our database.
                                </p>
                              </div>
                              <Form onSubmit={handleSignUp} className="auth-form">
                                <div className="mb-3">
                                  <label htmlFor="emailInput" className="form-label">
                                    Email
                                  </label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    required
                                    id="emailInput"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="passwordInput" className="form-label">
                                    Password
                                  </label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter your password"
                                  />
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                      I agree to the{" "}
                                      <Link to="#" className="text-white text-decoration-underline">
                                        Terms and conditions
                                      </Link>
                                    </label>
                                  </div>
                                </div>
                                {error && <p className="text-danger">{error}</p>}
                                <div className="text-center mb-2">
                                  <button
                                    type="submit"
                                    className="btn btn-white btn-hover w-100"
                                    disabled={isLoading}
                                  >
                                    {isLoading ? "Signing Up..." : "Sign Up"}
                                  </button>
                                </div>
                                <div className="text-center">
                                  <button type="button" className="btn btn-white btn-hover w-100">
                                    Sign Up with Google{" "}
                                    <Icon icon="flat-color-icons:google" />
                                  </button>
                                </div>
                              </Form>
                              <div className="mt-3 text-center">
                                <p className="mb-0">
                                  Already a member?{" "}
                                  <Link
                                    to="/signin"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Sign In{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;