import React, { useEffect } from 'react';

function SwaggerDocumentation() {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = "https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui.css";
    link.rel = "stylesheet";

    const script = document.createElement('script');
    script.src = "https://unpkg.com/swagger-ui-dist@4.5.0/swagger-ui-bundle.js";
    script.crossOrigin = "true";
    script.async = true;

    document.head.appendChild(link);
    document.body.appendChild(script);

    script.onload = () => {
      if (window.SwaggerUIBundle) {
        window.ui = window.SwaggerUIBundle({
          url: `https://mtech-api.com/client/api/schema`,
          dom_id: '#swagger-ui',
        });
      }
    };

    return () => {
    };
  }, []);

  return <div id="swagger-ui" />;
}

export default SwaggerDocumentation;
