import React from "react";
import { Card, CardBody, Col } from "reactstrap";

import { useAuth } from "Utils/AuthContext";

const LeftSideContent = () => {
  const { user, userSubscription, isSubUser } = useAuth();
  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="profile-sidebar me-lg-4">
          <CardBody className="p-4">
            <div className="text-center pb-4 border-bottom">
              <img
                src={user?.picture}
                alt=""
                className="avatar-lg img-thumbnail rounded-circle mb-4"
              />
              <h5 className="mb-0">{user?.email}</h5>
              <p className="text-muted">User ID </p>
            </div>

            <div className="mt-4">
              <h5 className="fs-17 fw-bold mb-3"> Account Overview </h5>
              <div className="profile-contact">
                <ul className="list-unstyled mb-0">
                  <li>
                    <div className="d-flex">
                      <label>Email</label>
                      <div>
                        <p className="text-muted text-break mb-0">
                        {user?.email}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <label> Status </label>
                      <div>
                        <p className="text-muted mb-0">{isSubUser ? 'Sub Account' : 'Main Account'}</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex">
                      <label>Tier</label>
                      <div>
                        <p className="text-muted mb-0">
                          {userSubscription?.tierName ? userSubscription?.tierName : 'Free Trial'}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
