import React, { useState, useEffect } from 'react';
import { useAuth } from 'Utils/AuthContext';
import { Form, Input, Button, Table, Spinner } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';

function SubUserManagement() {
    const {
        userSubscription,
        getAccessTokenSilently,
        isSubUser,
    } = useAuth();

    const [subUsers, setSubUsers] = useState([]);
    const [newSubUserEmail, setNewSubUserEmail] = useState('');
    const [maxReachedMessage, setMaxReachedMessage] = useState('');
    const apiURL = 'https://mtech-api.com';
    const [userTier, setUserTier] = useState(userSubscription?.data?.tier);
    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const fetchSubUsers = async () => {
        const token = await getAccessTokenSilently();
        const data = new URLSearchParams({ customer_id: userSubscription?.data?.customer_id }).toString();

        const response = await fetch(`${apiURL}/users-smart-hs/subscription/sub_user_search?${data}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            }
        });

        const result = response.ok ? await response.json() : [];
        setSubUsers(result);

        if ((result.length === 3 && userTier === 2) || (result.length === 10 && userTier === 3)) {
            setMaxReachedMessage('You have reached the maximum number of sub-users! Remove or upgrade your subscription to add more.');
        } else {
            setMaxReachedMessage('');
        }
    };

    useEffect(() => {
        fetchSubUsers();
    }, []);

    const handleAddSubUser = async (event) => {
        event.preventDefault();
        if (!newSubUserEmail) {
            toast.error('Please enter a valid email address.');
            return;
        }
        if (isSubUser) {
            toast.error('Sub-users cannot add new sub-users.');
            return;
        }

        setIsAdding(true);
        const data = {
            main_email: userSubscription?.data?.user_id,
            main_subId: userSubscription?.data?.sub_id,
            main_customer_id: userSubscription?.data?.customer_id,
            sub_email: newSubUserEmail,
            credits_used: 0
        };
        const token = await getAccessTokenSilently();

        const response = await fetch(`${apiURL}/users-smart-hs/subscription/new-sub-user`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        setIsAdding(false);
        if (response.ok) {
            toast.success('Sub-user added successfully.');
            fetchSubUsers();
            setNewSubUserEmail('');
        } else {
            toast.error('Failed to add sub-user.');
        }
    };

    const handleRemoveSubUser = async (subUserEmail) => {
        if (isSubUser) {
            toast.error('Sub-users cannot remove other sub-users.');
            return;
        }

        setIsRemoving(true);
        const token = await getAccessTokenSilently();

        const data = {
            customer_id: userSubscription?.data?.customer_id,
            sub_user_email: subUserEmail
        };

        const response = await fetch(`${apiURL}/users-smart-hs/subscription/remove_sub_user`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        setIsRemoving(false);
        if (response.ok) {
            toast.success('Sub-user removed successfully.');
            fetchSubUsers();
        } else {
            toast.error('Failed to remove sub-user.');
        }
    };

    return (
        <div>
            <ToastContainer />
            {maxReachedMessage ? (
                <p className="text-danger">{maxReachedMessage}</p>
            ) : (
                <Form onSubmit={handleAddSubUser} id="add_sub_user_form" className="d-flex align-items-center mb-3">
                    {!isSubUser && (
                        <Input
                            type="email"
                            value={newSubUserEmail}
                            onChange={(e) => setNewSubUserEmail(e.target.value)}
                            placeholder="New sub-user email"
                            required
                            className="me-2"
                            disabled={isAdding || isSubUser}
                        />
                    )}
                    {!isSubUser && (
                        <Button type="submit" color="primary" className="d-inline-block w-50" disabled={isAdding || isSubUser}>
                            {isAdding ? <Spinner size="sm" /> : 'Add SubUser'}
                        </Button>
                    )}
                </Form>
            )}

            <Table borderless>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Team Accounts</th>
                        <th>Credits Used</th>
                        {!isSubUser && <th>Remove User</th>}
                    </tr>
                </thead>
                <tbody>
                    {subUsers.map((subUser, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{subUser.sub_user_email}</td>
                            <td>{subUser.credits_used}</td>
                            {!isSubUser && (
                                <td>
                                    <Button
                                        onClick={() => handleRemoveSubUser(subUser.sub_user_email)}
                                        outline
                                        color="danger"
                                        size="sm"
                                        disabled={isRemoving}
                                    >
                                        {isRemoving ? <Spinner size="sm" /> : 'Remove'}
                                    </Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}

export default SubUserManagement;
