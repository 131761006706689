import React, { useEffect, useState } from "react";
import Section from "./Section";
import { createAuth0Client } from "@auth0/auth0-spa-js";
import $ from "jquery";
import SwaggerDocumentation from "./SwaggerDoc";
import { useAuth } from "Utils/AuthContext";
import { Spinner } from "reactstrap";

const apiURL = true ? "https://mtech-api.com" : "https://mtech-api.com";
const authConfig = {
    domain: "dev-1-io12a2.us.auth0.com",
    clientId: "uFhHddbIOSfQctYfjniL8Cgq0G6exGGU",
    audience: "https://dev-1-io12a2.us.auth0.com/api/v2/",
    apiAudience: "https://mtech-api.com",
    appUri: (false ? "https://mtech-app.com" : "http://localhost:3000") + "/api-doc",
};

const APIDocumentation = () => {
    const [auth0Client, setAuth0Client] = useState(null);
    const [isAuth, setIsAuth] = useState(false);
    const [token, setToken] = useState("");
    const [usage, setUsage] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        isFetchingCredit,
        userSubscription,
    } = useAuth();

    useEffect(() => {
        const initAuth0 = async () => {
            const client = await createAuth0Client({
                domain: authConfig.domain,
                clientId: authConfig.clientId,
                useRefreshTokens: true,
                cacheLocation: "localstorage",
                authorizationParams: {
                    redirect_uri: authConfig.appUri,
                    audience: authConfig.apiAudience,
                },
            });
            setAuth0Client(client);

            // Check if the URL contains the Auth0 callback parameters
            const query = window.location.search;
            if (query.includes("code=") && query.includes("state=")) {
                await client.handleRedirectCallback();
                window.history.replaceState({}, document.title, "/api-doc");
                setIsAuth(true); // Set authentication state to true
                await configureAuthenticatedUI(client);
            } else {
                checkAuthStatus(client); // Check authentication status if not in callback state
            }
        };

        initAuth0();

        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        console.log("Monthly Cycle Start Date:", firstDay.toLocaleString());
    }, []);

    const checkAuthStatus = async (client) => {
        if (client) {
            const authenticated = await client.isAuthenticated();
            setIsAuth(authenticated);
            if (authenticated) {
                await configureAuthenticatedUI(client);
            }
        }
    };

    const configureAuthenticatedUI = async (client) => {
        try {
            const user = await client.getUser();
            const userToken = await client.getTokenSilently();
            sessionStorage.setItem("auth0Token", userToken);
            sessionStorage.setItem("userId", user.email);
            setIsAuth(true);
            fetchToken(user.email, userToken);
        } catch (err) {
            console.error(err);
            setIsAuth(false);
            await client.logout({ returnTo: authConfig.appUri });
        }
    };

    const fetchToken = async (email, token) => {
        const getUrl = `${apiURL}/users/publicapi/token?email=${email}`;
        setLoading(true);

        $.ajax({
            url: getUrl,
            type: "GET",
            beforeSend: (request) => {
                request.setRequestHeader("content-type", "application/json");
                request.setRequestHeader("Authorization", `Bearer ${token}`);
            },
        })
            .done((res) => {
                const token = res.data.token;
                setToken(token);
                setUsage(`${res.data.monthly_usage} / 100`);
                setLoading(false);
            })
            .fail((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const login = async () => {
        await auth0Client.loginWithRedirect();
    };

    return (
        <React.Fragment>
            <Section />
            <div className="subscribe-area text-center text-light relative default-padding">
                <div className="container">
                    <div className="subscribe-items" style={{ padding: "80px 50px", background: "linear-gradient(45deg,#02ebad 0,#5a57fb 50%)", borderRadius: "10px", boxShadow: "0 10px 30px 0 rgb(44 130 237 / 40%)", position: "relative", overflow: "hidden" }}>

                        <div className="row align-center">
                            <div className="col-lg-8 offset-lg-2">
                                <h2> Get free SmartHSCodes API Token </h2>
                                <p>No payment details required. Test our API with free credits.</p>
                                <div className="button">

                                    {isFetchingCredit ? (
                                        <Spinner color="primary" />
                                    ) : (
                                        userSubscription?.api?.data ? (
                                            <div id="token-area" className="mt-3">
                                                    Your token is:<br />
                                                    <p style={{ color: "blue" }}>{userSubscription.api.apiData.token}</p>
                                                    Please copy and paste it in the API Explorer.<br />
                                                    Usage Limit: {userSubscription.api.apiData.monthly_quota} calls per month<br />
                                                    Monthly Cycle Start Date: {new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toLocaleString()}<br />
                                                    Current Usage: <span id="usage">{userSubscription.api.apiData.monthly_usage}</span>
                                                </div>
                                        ) : (
                                        <React.Fragment>
                                            {!isAuth && (
                                                <button className="btn circle text-light btn-sm" onClick={login}>
                                                    Login / Sign up
                                                </button>
                                            )}
                                            {isAuth && (
                                                <button className="btn btn-primary" onClick={() => fetchToken(sessionStorage.getItem("userId"), sessionStorage.getItem("auth0Token"))}>
                                                    Get Trial Token
                                                </button>
                                            )}
                                            {token && (
                                                <div id="token-area" className="mt-3">
                                                    Your token is:<br />
                                                    <p style={{ color: "blue" }}>{token}</p>
                                                    Please copy and paste it in the API Explorer.<br />
                                                    Usage Limit: 100 calls per month<br />
                                                    Monthly Cycle Start Date: {new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toLocaleString()}<br />
                                                    Current Usage: <span id="usage">{usage}</span>
                                                </div>
                                            )}
                                        </React.Fragment>
                                            
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SwaggerDocumentation></SwaggerDocumentation>
            {loading && <div>Loading...</div>}
        </React.Fragment>
    );
};

export default APIDocumentation;
