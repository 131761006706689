import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';

const RainbowButton = ({ text, ...props }) => {
  useEffect(() => {
    // Dynamically inject the keyframes into the document
    const styleSheet = document.styleSheets[0];
    const keyframes =
      `@keyframes rotate {
        0% {
          transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
          transform: translate(-50%, -50%) rotate(360deg);
        }
      }`;
    
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
  }, []);

  const buttonStyle = {
    position: 'relative',
    display: 'flex',
    backgroundColor: 'transparent',
    border: 'none',
    width: props.width || '200px',
    height: '50px',
  };

  const borderStyle = {
    position: 'absolute',
    overflow: 'hidden',
    borderRadius: '31px',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    filter: 'blur(8px)',
    transition: 'transform 0.2s ease-in-out',
  };

  const rainbowStyle = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '250%',
    height: '500%',
    background: `conic-gradient(
      from 0deg at 50% 50%,
      #42eff5,
      #ca8aff,
      #ff73ec,
      #ffa340,
      #ffe940,
      #42eff5,
      #42eff5
    )`,
    transformOrigin: 'center',
    animation: 'rotate 2s linear infinite',
    transition: 'transform 0.2s ease-in-out',
  };

  const textStyle = {
    position: 'absolute',
    display: 'flex',
    left: '1px',
    top: '1px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1f1f1f',
    color: '#fff',
    fontWeight: 500,
    fontSize: '0.8rem',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '30px',
    width: 'calc(100% - 2px)',
    height: 'calc(100% - 2px)',
    transition: '200ms',
    cursor: 'pointer',
  };

  const hoverStyle = {
    boxShadow: 'inset 0px 0px 10px 0px rgba(0,0,0,0.9)',
    fontSize: '0.84rem',
  };

  const handleMouseEnter = (e) => {
    Object.assign(e.target.style, hoverStyle);

    const rainbow = e.target.previousSibling.firstChild;
    rainbow.style.animation = 'rotate 0.8s linear infinite';
  };

  const handleMouseLeave = (e) => {
    e.target.style.boxShadow = 'none';
    e.target.style.fontSize = '0.8rem';

    const rainbow = e.target.previousSibling.firstChild;
    rainbow.style.animation = 'rotate 2s linear infinite';
  };

  return (
    <button style={buttonStyle} disabled={props.disabled}>
      <div style={borderStyle}>
        <div style={rainbowStyle}></div>
      </div>
      <div
        style={textStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...props}
      >
        {text} <Icon icon="mingcute:ai-fill" className='ms-2 text-white fs-5'/>
      </div>
    </button>
  );
};

export default RainbowButton;
