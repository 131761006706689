import React from "react";
import { Col, Card, CardBody } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import Link from "Utils/LinkWrapper";
import { ConditionalWrapper } from "Utils/Utils";
import BlurRandomTextWrapper from "Utils/BlurRandomTextWrapper";

const MeasureItem = ({ title, item, data }) => (
  <div id={title} className="candidate-education-content mt-4 d-flex">
    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
      <i className="uil uil-percentage"></i>
    </div>
    <div className="ms-4">

      <Link to={`/regulation-details?id=${encodeURIComponent(item.code)}&type=${title}&product=${encodeURIComponent(data.metadata.product)}&destination=${data.metadata.destination}&origin=${data.metadata.origin}`} className="mb-0"> <h6 className="fs-16 mb-1">{title + " - " + item.subject_product + " - " + item.hs_section_label}</h6></Link>
      <p className="mb-0 text-muted">Exporting Country/Customs Territory: {item.exporting_member}</p>
      <p className="mb-0 text-muted">Subject Product: {item.subject_product}</p>
      <p className="mb-0 text-muted">HS Section: {item.hs_section_label}</p>
      <p className="mb-0 text-muted">Range Of Individual Margins: <b>{item.duty_measure_detail.Measure?.RangeOfIndividualMargins}</b></p>
      <p className="mb-0 text-muted">All Other Rates: <b>{item.duty_measure_detail.Measure?.Duties?.AllOtherRates}</b></p>
      <p className="mb-0 text-muted">HS Codes: <b>{item.mtechHSCode ? item.mtechHSCode.replace("{", "").replace("}", "") : ""}</b></p>
    </div>
  </div>
);

const Regulation = ({ title, type, date, date_type, data, id }) => (
  <div id={title} className="candidate-education-content mt-4 d-flex">
    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
      <i className="uil uil-exclamation-triangle"></i>
    </div>
    <div className="ms-4">
      <Link to={`/regulation-details?id=${encodeURIComponent(id)}&type=${type}&product=${encodeURIComponent(data.metadata.product)}&destination=${data.metadata.destination}&origin=${data.metadata.origin}`} className="mb-0"><h6 className="fs-16 mb-1" style={{ wordBreak: 'break-word' }}>{type}</h6></Link>
      {title}
      <p className="mb-0 text-muted">{date_type}: {date}</p>
    </div>
  </div>
)

const RightSideContent = ({ data, hasBurnedCredit }) => {
  console.log(data)
  const importDutyData = data?.importDuty?.FTAImportDuty?.find((item, index) =>
    index === 0 || item.find((subItem) => subItem.HSCode.slice(0, 6) === data?.hs?.data?.six_digit_codes[0]?.searchTerm.slice(0, 6))
  );

  const {
    antidumping_measures,
    antidumping_investigations,
    countervailing_measures,
    countervailing_investigations,
  } = data?.antidumping || {};

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="candidate-details ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4 candidate-personal-detail">
            {/* HS Code Description */}
            <div>
              <h6 className="fs-17 fw-semibold mb-3">HS Code Description </h6>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code2 || <Skeleton width={100} />} :</b>
                {data?.hs?.data?.six_digit_codes[0]?.name2 || <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code4 || <Skeleton width={100} />} :</b>
                {data?.hs?.data?.six_digit_codes[0]?.name4 || <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.six_digit_codes[0]?.code6 || <Skeleton width={100} />} :</b>
                {data?.hs?.data?.six_digit_codes[0]?.name6 || <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-2">
                <b>{data?.hs?.data?.national_tariff_lines[0]?.code || <Skeleton width={100} />} :</b>
                {data?.hs?.data?.national_tariff_lines[0]?.desc || <Skeleton width={500} />}
              </p>
              <p className="text-muted mb-0">
                <b>{data?.hs?.data?.trans_national_tariff_lines[0]?.code || <Skeleton width={100} />} (English Translated) :</b>
                {data?.hs?.data?.trans_national_tariff_lines[0]?.desc || <Skeleton width={500} />}
              </p>
            </div>

            <ConditionalWrapper
              condition={hasBurnedCredit}
              wrapper={(children) => <BlurRandomTextWrapper>{children}</BlurRandomTextWrapper>}
            >
              {/* Import Duty Details */}
              <div className="candidate-education-details mt-4 pt-3">
                <h6 id="import-duty" className="fs-17 fw-bold mb-0">Import Duty Details</h6>
                {!data?.importDuty?.FTAImportDuty && (
                  <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      <i className="uil uil-percentage"></i>
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                      <p className="mb-2 text-muted"><Skeleton width={150} /></p>
                    </div>
                  </div>
                )}
                {importDutyData && importDutyData.map((item, index) => {
                  const ftaName = item.FTANameFull || item.FTAName;
                  const dutyData = item.ImportDutyData;
                  const agreementData = item.AgreementData;
                  return (
                    <React.Fragment key={index}>
                      {dutyData.map((dutyItem, dutyIndex) => {
                        const rate = dutyItem.ImportDutyRate === "T" ? "N/A" : dutyItem.ImportDutyRate || "N/A";
                        const type = dutyItem.ImportDutyType || agreementData.Type;
                        return (
                          <div key={dutyIndex} id={ftaName} className="candidate-education-content mt-4 d-flex">
                            <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                              <i className="uil uil-percentage"></i>
                            </div>
                            <div className="ms-4">
                              <h6 className="fs-16 mb-1">
                                {type} : <b>{rate}</b>
                              </h6>
                              <p className="mb-2 text-muted">
                                <Link to={`/regulation-details?id=${encodeURIComponent(ftaName)}&type=Import Duty&product=${encodeURIComponent(data.metadata.product)}&destination=${data.metadata.destination}&origin=${data.metadata.origin}`}>{ftaName || <Skeleton width={150} />}</Link>
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </div>

              {/* Antidumping / Countervailing Rate Detail */}
              <div className="candidate-education-details mt-4 pt-3">
                <h6 className="fs-17 fw-bold mb-0">Antidumping / Countervailing Rate</h6>

                {!data?.antidumping && (
                  <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      <i className="uil uil-percentage"></i>
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                      <p className="mb-0 text-muted"><Skeleton width={180} /></p>
                      <p className="mb-0 text-muted"><Skeleton width={150} /></p>
                      <p className="mb-0 text-muted"><Skeleton width={100} /></p>
                      <p className="mb-0 text-muted"><b><Skeleton width={130} /></b></p>
                      <p className="mb-0 text-muted"><b><Skeleton width={160} /></b></p>
                    </div>
                  </div>
                )}

                {/* Render Antidumping Measures */}
                {(antidumping_measures?.length === 0) &&
                  (antidumping_investigations?.length === 0) &&
                  (countervailing_measures?.length === 0) &&
                  (countervailing_investigations?.length === 0) ? (
                  <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      <i className="uil uil-percentage"></i>
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1">N/A</h6>
                      <p>No data found</p>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {antidumping_measures?.map((measure, index) => (
                      <MeasureItem key={index} title="Antidumping Measures" item={measure} data={data} />
                    ))}
                    {antidumping_investigations?.map((investigation, index) => (
                      <MeasureItem key={index} title="Antidumping Investigations" item={investigation} data={data} />
                    ))}
                    {countervailing_measures?.map((measure, index) => (
                      <MeasureItem key={index} title="Countervailing Measures" item={measure} data={data} />
                    ))}
                    {countervailing_investigations?.map((investigation, index) => (
                      <MeasureItem key={index} title="Countervailing Investigations" item={investigation} data={data} />
                    ))}
                  </React.Fragment>
                )}
              </div>

              {/* Regulations Detail */}
              <div className="candidate-education-details mt-4 pt-3">
                <h6 id="regulations" className="fs-17 fw-bold mb-0">Regulations</h6>

                {!data?.qr?.data || !data?.pr?.ProductRequirementsDetails || !data?.importLicensing?.data ? (

                  <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      <i className="uil uil-exclamation-triangle"></i>
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1"><Skeleton width={120} /></h6>
                      <p className="mb-0 text-muted"><Skeleton width={150} /></p>
                      <p className="mb-0 text-muted"><Skeleton width={100} /></p>
                    </div>
                  </div>
                ) : null}

                {(data.qr?.data?.length === 0 && data.importLicensing?.data?.length === 0 && data.pr?.ProductRequirementsDetails?.length === 0 && data?.euTaric?.length === 0) ? (
                  <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      <i className="uil uil-exclamation-triangle"></i>
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1">N/A</h6>
                      <p className="mb-0 text-muted">No data found</p>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {data.qr && data.qr.data.map((item, index) => {
                      return (
                        <Regulation key={index} title={item.general_description} type="Import Export Restriction" date={item.in_force_from} date_type={"In Force From"} data={data} id={item.id} />
                      )
                    })}

                    {data.importLicensing && data.importLicensing.data.map((item, index) => {
                      const restrictionName = item.title;
                      return (
                        <Regulation key={index} title={restrictionName} type="Import Licensing" date={item.legislation_detail.LatestPublished} date_type={"Latest Published"} data={data} id={restrictionName} />
                      );
                    })}

                    {data.pr && data.pr.ProductRequirementsDetails.map((item, index) => {
                      const restrictionName = item.RequirementTitle;
                      return (
                        <Regulation key={index} title={restrictionName} type="Product Requirement" date={new Intl.DateTimeFormat('en-GB').format(new Date(item.distributionDate))} date_type={"Distribution Date"} data={data} id={item.documentSymbol} />
                      );
                    })}

                    {data.euTaric && data.euTaric?.[0]?.measures_details?.map((item, index) => {
                      return (
                        <Regulation key={index} title={item.description} type="EU Commission" date={data.euTaric?.[0]?.last_update} date_type={"Last Update"} data={data} id={data.euTaric?.[0]?.nomenclature_code} />
                      )
                    })}
                  </React.Fragment>
                )}
              </div>
            </ConditionalWrapper>
            
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
