import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_live_51Pvbaj2LftaZnN8ohZQgCybKMyERSR4ZHvWlDthtbxy8k51uQlCEXKONDFUiXWLMIcAvQjMIJCtxbK5t8w6bdxDQ003kdBHd4r');
  }
  return stripePromise;
};

export default getStripe;