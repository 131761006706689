import CryptoJS from "crypto-js";
import { getConfig } from "config";
const config = getConfig();

function decrypt(text) {
  const secretKey = "6f8f57715090da2632453988d9c54c66e4a0494643ccecd1a10bd2b64aaf37f4";
  const textParts = text.split(":");
  const iv = CryptoJS.enc.Hex.parse(textParts.shift());
  const encryptedText = textParts.join(":");
  const encryptedHexStr = CryptoJS.enc.Hex.parse(encryptedText);
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

  const decrypted = CryptoJS.AES.decrypt(
    encryptedBase64Str,
    CryptoJS.enc.Hex.parse(secretKey),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
}

function cacheData(url, data) {
  const expirationTime = Date.now() + 600000;
  const cacheItem = {
    data,
    expiration: expirationTime,
  };

  try {
    localStorage.setItem(url, JSON.stringify(cacheItem));
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      console.warn('LocalStorage is full. Clearing storage and retrying...');
      localStorage.clear();
    } else {
      console.error('Failed to cache data:', error);
    }
  }
}

function getCachedData(url) {
  const cachedItem = localStorage.getItem(url);
  if (!cachedItem) return null;

  const { data, expiration } = JSON.parse(cachedItem);
  if (Date.now() > expiration) {
    localStorage.removeItem(url);
    return null;
  }

  return data;
}

async function fetchEnc(url, options = {}) {
  const isEncrypted = config.encrypt_response;
  url = new URL(url);
  if (isEncrypted) {
    url.searchParams.set("encrypt", "true");
  } else {
    url.searchParams.set("encrypt", "false");
  }

  const shouldCache = config.cache_response;
  if (shouldCache) {
    const cachedData = getCachedData(url);
    if (cachedData) {
      return {
        json: async () => {
          return cachedData;
        },
      };
    }
  }

  const headers = {
    ...options.headers,
    "Accept": isEncrypted ? "application/text" : "application/json",
    "x-user-email": isEncrypted ? "landing@gmail.com" : "no.reply.moaah@gmail.com",
    "x-user-sub": "KzNZJAK4TamQrHX34EayryukBO9sxjam",
  };

  const response = await fetch(url, { ...options, headers });
  const responseText = await response.text();

  if (isEncrypted) {
    const decryptedData = decrypt(responseText);
    const jsonData = JSON.parse(decryptedData);
    if (shouldCache) {
      cacheData(url, jsonData);
    }
    return {
      ...response,
      json: async () => jsonData,
    };
  }

  const jsonData = JSON.parse(responseText);
  if (shouldCache) {
    cacheData(url, jsonData);
  }
  return {
    ...response,
    json: async () => jsonData,
  };
}

export default fetchEnc;
